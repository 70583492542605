<template>

<div class="login" :class="{'is-failed': is.failed}">

	<div class="login-box">

		<app-logo class="login-logo" />

		<template v-if="is.failed">

			<div class="login-text">{{ reasonText[reason] }}</div>

		</template>

		<app-input-text :noLine="true" :disabled="is.loggingin" :stacked="true" v-model="model.email" placeholder="Enter e-mail address" maxlength="128" />
		<app-input-text :noLine="true" :disabled="is.loggingin" :stacked="true" ref="password" :password="true" v-model="model.password" placeholder="Enter password" maxlength="128" />

		<app-button text="Login" class="login-button" v-on:click.native.stop="onLoginClick" :disabled="!isLoginValid" :loading="is.loggingin" />

		<router-link :to="{ name: 'LoginForgot' }" class="login-link">Forgot password</router-link>

	</div>

</div>

</template>

<script>

export default {

	data: function() {

		return {
			reasonText: {
				unauthorised: 'Sorry, you do not have a badge for this convention.',
				unrecognised: 'Sorry, these details did not match any account. Please check and try again.'
			},
			is: {
				loggingin: false,
				failed: false
			},
			reason: '',
			model: {
				email: '',
				password: ''
			}
		}

	},

	computed: {

		isLoginValid: function() {

			return this.model.email && this.model.password
		
		}

	},

	methods: {

		onLoginClick: function() {

			this.is.loggingin = true

			this.$store.dispatch('session/login', {
				model: this.model
			}).then(function() {

				this.reason = ''

				this.$router.push({
					path: (this.$route.query.redirect) ? this.$route.query.redirect : '/'
				})

			}.bind(this), function(json) {
				
				this.is.loggingin = false
				this.is.failed = true
				this.reason = json.reason

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.login {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	background-color: #1e3054;
	padding-left: 50%;
	display: flex;
	align-items: center;
}

.login-logo {
	margin-bottom: 10px;
}

.login.is-failed {
	background-color: #c55b5b;
}

.login:before {
	content: '';
	position: absolute;
	left: 0px;
	top: 0px;
	bottom: 0px;
	width: 50%;
	background-image: url(https://api.bigbox.dev/files/uploads/62e2ed3b99bbe.jpg);
	background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #2f3a4a;
    filter: sepia(100%) hue-rotate(190deg) saturate(400%) brightness(50%);
}

.is-mobile .login {
	padding-left: 0%;
	display: block;
}

.is-mobile .login:before {
	width: 100%;
}

.login-box {
	width: 365px;
	border-radius: 0px 10px 10px 0px;
	padding: 40px;
}

.is-mobile .login-box {
	position: absolute;
	left: 0px;
	bottom: 0px;
	background-color: #1e3054;
	width: 100%;
	max-width: 100%;
	padding: 20px;
	border-radius: 0px;
}

.login-title {
	text-align: center;
	color: #fff;
	margin-bottom: 20px;
}

.login-title span {
	font-family: 'Courier New';
	font-size: 32px;
	line-height: 32px;
}

.login-title span:nth-child(2) {
	opacity: 0.5;
	font-size: 32px;
	line-height: 32px;
}

.login-text {
	color: #fff;
	font-size: 14px;
	line-height: 18px;
	margin-bottom: 20px;
}

.login-convention {
	display: flex;
	margin-bottom: 10px;
	align-items: center;
	background-color: #fff;
	border-radius: 10px;
}

.login-convention-logo {
	width: 64px;
	height: 64px;
	border: 5px solid #fff;
	border-radius: 10px;
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-image: url(https://static.wixstatic.com/media/c05de0_f353a33ed0ea482994f74f208189e142~mv2_d_1372_1474_s_2.gif);
}

.login-convention-text {
	color: #1e3054;
}

.login-convention-text-name {
	font-size: 20px;
	font-weight: 600;
}

.login-convention-text-date {
	font-size: 14px;
	opacity: 0.75;
	margin-top: 3px;
}

.login-button {
	width: 100%;
	margin-top: 20px;
}

.login-link {
	font-size: 12px;
	color: #fff;
	margin-top: 20px;
	text-align: center;
	display: block;
	letter-spacing: 1px;
	cursor: pointer;
	text-transform: lowercase;
	opacity: 0.75;
}

.login-link:hover {
	text-decoration: underline;
	opacity: 1;
}

</style>
